import { useAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';

import { shippingLocationOpen } from '../../../../state/shipping-location';
import Slider from '../../../ui/slider';

const LazyOptions = dynamic(() => import('./options'), { ssr: false });

const ShippingSelectorInDrawer: FunctionComponent<unknown> = (props) => {
  const { query } = useRouter();
  const [open, setOpen] = useAtom(shippingLocationOpen);

  useEffect(() => {
    if (query.showShippingLocation === 'true') {
      setOpen(true);
    }
  }, [query]);

  return (
    <Slider
      isOpen={open}
      onClose={() => setOpen(false)}
      enterFrom="right"
      isFullscreenMobile={true}
    >
      <LazyOptions />
    </Slider>
  );
};
export default ShippingSelectorInDrawer;
